import React from 'react';
import { useLocation } from 'react-router-dom';

const QuoteDetailsPage = () => {
  const location = useLocation();
  const { imagePath, title, quotes } = location.state;

  return (
    <div className="QuoteDetailsPage-pageContainer">
      <header className="QuoteDetailsPage-header">{title}</header>
      <div className="QuoteDetailsPage-quotesContainer">
        {quotes.map((quote, index) => (
          <div className="QuoteDetailsPage-quoteItem" key={index}>
            <img src={imagePath} alt={title} className="QuoteDetailsPage-quoteImage" />
            <p className="QuoteDetailsPage-quoteText">{quote}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteDetailsPage;
