import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginApi } from "../services/Api";
import { GenerateToken } from "../services/Firebase";

const SignIn = () => {
  useEffect(() => {
    handleToken();
  }, []);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [token,setToken] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(""); // For displaying errors
  const navigate = useNavigate();

  const handleToken = async()=>{
    const token = await GenerateToken();
    if(token) 
    {
      setToken(token);
    }
  }

  // Input validation function
  const validateInputs = () => {
    if (!phone.trim()) {
      setError("Phone number is required.");
      return false;
    }
    if (!/^\d{10}$/.test(phone)) {
      setError("Invalid phone number. It must be 10 digits.");
      return false;
    }
    if (!password.trim()) {
      setError("Password is required.");
      return false;
    }
    // if(!token)
    // {
    //   setError("Unable to generate the Token");
    //   return false;
    // }
    setError(""); // Clear any previous errors
    return true;
  };

  // Async function to handle sign-in
  const handleSignIn = async () => {
    if (validateInputs()) {
      try {
        // Call the API
        const response = await LoginApi({
          phoneNumber: phone,
          password: password,
          token:token
        });

        // Store response data in localStorage
        if (response) {
          localStorage.setItem("userData", JSON.stringify(response));

          console.log("User signed in successfully:", response);
          navigate("/", { replace: true });
        }
      } catch (err) {
        console.error("Login error:", err);
        setError("Invalid credentials. Please try again.");
      }
    }
  };

  return (
    <div className="sign-in-container">
      {error && <p className="error-message">{error}</p>} {/* Display error */}
      <input
        className="input-field"
        type="tel"
        placeholder="Enter your phone number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <input
        className="input-field"
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="submit-button" onClick={handleSignIn}>
        <h2 className="submit-text">Sign In</h2>
      </button>
    </div>
  );
};

export default SignIn;
