import React from 'react';
import peopleImage from '../assets/group.png';
import startTalking from '../assets/PsychologistButton.png';


const PromoBanner = () => {
  return (
    <div className="promo-banner-parent">
      <div className="promo-banner-container">
        <div className="inner-container">
          <h2 className='promo-banner-large-text'>On a Mission to Make</h2>
          <h3 className='promo-banner-small-text'>Mental Health Affordable</h3>
          <img src={startTalking} className="start-talking-image" alt="Good-People" />
        </div>
        <div className="inner-container">
          <img src={peopleImage} className="people-image" alt="Good-People"/>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;
