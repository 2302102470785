import React, { useState } from "react";

function PremiumRate() {
  const [hours, setHours] = useState(1); // Default 1 hour
  const ratePerHour = 20; // Rate per hour in Rs

  // Calculate total amount
  const totalAmount = hours * ratePerHour;

  // Handlers for increment and decrement
  const incrementHours = () => setHours(hours + 1);
  const decrementHours = () => {
    if (hours > 1) setHours(hours - 1); // Minimum 1 hour
  };

  return (
    <div className="premium-rate-container">
      <h2 className="premium-rate-title">Buy Credits/Time</h2>
      <p className="premium-rate-description">
        Purchase time at <strong>Rs {ratePerHour}/hour</strong>
      </p>
      <div className="premium-rate-counter-container">
        <button onClick={decrementHours} className="premium-rate-counter-button">
          -
        </button>
        <span className="premium-rate-hours-text">{hours} hour(s)</span>
        <button onClick={incrementHours} className="premium-rate-counter-button">
          +
        </button>
      </div>
      <button className="premium-rate-buy-button">
        Buy Now (Rs {totalAmount})
      </button>
    </div>
  );
}

export default PremiumRate;
