
import React from "react";
import Header from "../components/Header";
import PodcastCard from "../components/PodcastCard";
import PodcastTile from "../components/PodcastTile";  



const PadcastPage = () => {
  return (
    <>
      <Header />
      <div className="premium-page-header">
            <h3 className="premium-page-header-title">Podcast</h3>
            <h3 className="premium-page-header-message">Please don't disclose the contacts, trust the app for secure conversations</h3>
        </div>
      <div className="podcast-container">
      
        <PodcastCard/>
        <PodcastTile/>
      </div>
    </>
  );
};

export default PadcastPage;

