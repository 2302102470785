import React, { useState } from "react";
import DownArrow from '../assets/down-arrow.png';

const FaqTile = ({ logo, question, description }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="faq-tile-container">
      <div className="faq-header" onClick={toggleDropdown}>
        <img src={logo} alt="Logo" className="faq-logo" />
        <span className="faq-question">{question}</span>
        <img
          src={DownArrow} 
          alt="Down Arrow"
          className={`faq-arrow ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && <div className="faq-description">{description}</div>}
    </div>
  );
};

export default FaqTile;
