import React, { useState } from "react";
import { useNavigate,useLocation, replace } from "react-router-dom";
import { SendFriendRequestApi } from "../services/Api";



const RatingPage = () => {
  const location = useLocation();
  const { senderId, receiverId,callerName } = location.state;
  console.log('RatingPage params',senderId,receiverId);
  const navigate = useNavigate();
  const [flag,setFlag] = useState(true);

  const handleRequestSend =async()=>{
    const response = await SendFriendRequestApi({senderId:senderId,receiverId:receiverId});
    if(response){
      console.log('SendFriendRequestApi response:',response);
    }
    navigate('/', { replace: true });
  }
  return (
    <div className="rating-container">
      <header className="QuoteDetailsPage-header">Ratings</header>
      {flag?<div className="rating-like-container">
        <h2 className="rating-text">Would you like to talk to {callerName} Again?</h2>
        <div className="rating-button-container">
          <button className="rating-button" onClick={()=>{setFlag(false)}}>
            <h4 className="rating-button-text">YES</h4>
          </button>
          <button className="rating-button" onClick={()=>{navigate('/', { replace: true })}}>
            <h4 className="rating-button-text">NO</h4>
          </button>
        </div>
      </div>:<div className="rating-like-container">
        <h2 className="rating-text">Send Friend request to Shubh?</h2>
        <div className="rating-button-container">
          <button className="rating-button" onClick={handleRequestSend}>
            <h4 className="rating-button-text">YES</h4>
          </button>
          <button className="rating-button" onClick={()=>{navigate('/', { replace: true })}}>
            <h4 className="rating-button-text">NO</h4>
          </button>
        </div>
      </div>}
    </div>
  );
};

export default RatingPage;
