import React from "react";
import lonelinessImage from "../assets/loneliness.png";
import stressImage from "../assets/stress.png";
import relationshipImage from "../assets/relationship.png";
import sadnessImage from "../assets/sadness.png";
// more icons
import abusedImage from "../assets/emotions/Abused.png";
import angryImage from "../assets/emotions/Angry.png";
import burnoutImage from "../assets/emotions/burnout.png";
import cheatedImage from "../assets/emotions/Cheated.png";
import emptyImage from "../assets/emotions/empty.png";
import failedImage from "../assets/emotions/Failed.png";
import griefImage from "../assets/emotions/Grief.png";
import guiltyImage from "../assets/emotions/guilty.png";
import harassedImage from "../assets/emotions/harassed.png";
import heart_brokenImage from "../assets/emotions/heart-broken.png";
import helplessImage from "../assets/emotions/helpless.png";
import hopelessImage from "../assets/emotions/Hopeless.png";
import insecureImage from "../assets/emotions/insecure.png";
import insultedImage from "../assets/emotions/insulted.png";
import jealousImage from "../assets/emotions/jealous.png";
import lossImage from "../assets/emotions/Loss.png";
import missingImage from "../assets/emotions/Missing.png";
import overthinkingImage from "../assets/emotions/overthinking.png";
import regretImage from "../assets/emotions/regret.png";
import rejectedImage from "../assets/emotions/Rejected.png";
import scaredImage from "../assets/emotions/Scared.png";
import self_doubtImage from "../assets/emotions/Self-doubt.png";
import self_loveImage from "../assets/emotions/self-love.png";
import seperatedImage from "../assets/emotions/seperated.png";
import tiredImage from "../assets/emotions/tired.png";
import Marquee from "react-fast-marquee";

const EmotionList = () => {
  const data = [
    { id: "1", image: lonelinessImage, text: "loneliness" },
    { id: "2", image: stressImage, text: "stress" },
    { id: "3", image: relationshipImage, text: "relationship" },
    { id: "4", image: sadnessImage, text: "sadness" },
    { id: "5", image: abusedImage, text: "abused" },
    { id: "6", image: angryImage, text: "angry" },
    { id: "7", image: burnoutImage, text: "burnout" },
    { id: "8", image: cheatedImage, text: "cheated" },
    { id: "9", image: emptyImage, text: "empty" },
    { id: "10", image: failedImage, text: "failed" },
    { id: "11", image: griefImage, text: "grief" },
    { id: "12", image: guiltyImage, text: "guilty" },
    { id: "13", image: harassedImage, text: "harassed" },
    { id: "14", image: heart_brokenImage, text: "heart-broken" },
    { id: "15", image: helplessImage, text: "helpless" },
    { id: "16", image: hopelessImage, text: "hopeless" },
    { id: "17", image: insecureImage, text: "insecure" },
    { id: "18", image: insultedImage, text: "insulted" },
    { id: "19", image: jealousImage, text: "jealous" },
    { id: "20", image: lossImage, text: "loss" },
    { id: "21", image: missingImage, text: "missing" },
    { id: "22", image: overthinkingImage, text: "overthinking" },
    { id: "23", image: regretImage, text: "regret" },
    { id: "24", image: rejectedImage, text: "rejected" },
    { id: "25", image: scaredImage, text: "scared" },
    { id: "26", image: self_doubtImage, text: "self-doubt" },
    { id: "27", image: self_loveImage, text: "self-love" },
    { id: "28", image: seperatedImage, text: "seperated" },
    { id: "29", image: tiredImage, text: "tired" },
  ];

  return (
    <div className="emotion-list-container">
      <Marquee speed={30} gradient={true} gradientColor="white" gradientWidth={70}>
        {" "}
        {data.map((item) => (
          <div className="emotion-item" key={item.id}>
            <img src={item.image} alt={item.text} className="emotion-image" />
            <p className="emotion-text">{item.text}</p>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default EmotionList;
