import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import EmotionList from "../components/EmotionList";
import PromoBanner from "../components/PromoBanner";
import FaqTile from "../components/FaqTile";
import startSpeaking from "../assets/start-speaking.png";
import questionsLogo from "../assets/qa.png";
import salary from "../assets/salary.png";
import how from "../assets/how.png";
import hourglass from "../assets/hour-glass.png";
import premium from "../assets/premium.png";
// Import the CSS file
import "../styles/Home.css";

const HomePage = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log('UserData value in HomePage',userData);
  const navigate = useNavigate();
  const faqData = [
    {
      logo: salary,
      question: "Is it Free or Paid?",
      description:
        "The app offers both free and paid services. The basic features are available for free, allowing users to connect and interact with others. However, some advanced features, such as premium support, specialized services, and extended call durations, are part of our paid subscription plan.",
    },
    {
      logo: premium,
      question: "Is it Available 24*7?",
      description:
        "Yes, the app is available 24/7. You can connect with others at any time, regardless of your time zone. Whether it's day or night, you'll always find someone to talk to, whether you're seeking support or providing it. Our platform ensures that help is always just a call away.",
    },
    {
      logo: hourglass,
      question: "Is Premium Worth?",
      description:
        "The Premium plan unlocks additional features such as unlimited call durations, priority matching for specific support requests, and access to expert consultations. For users who are looking for more personalized and extended interactions, the Premium subscription provides great value. If you're looking for an enhanced experience, we believe Premium is definitely worth it!",
    },
    
    {
      logo: how,
      question: "How does it work?",
      description:
        "The app connects users based on their preferences, allowing them to talk to others who need or can provide support. Whether you're seeking help or offering assistance, the app facilitates seamless voice calls to foster meaningful conversations. Simply sign up, select your interest, and you'll be connected with someone who matches your needs or can offer help.",
    },
  ];

  const handleStartSpeakingClick = () => {
    if(userData)
    {
      navigate("/talking");
    }
    else{
      navigate('/login');
    }
  };

  return (
    <div className="page-container">
      <Header />
      <div style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
      <EmotionList />
      </div>
      <PromoBanner />
      <div
        className="start-speaking-container"
        onClick={handleStartSpeakingClick}
      >
        <img
          src={startSpeaking}
          alt="Start Speaking"
          className="start-speaking-image"
        />
      </div>
      <div className="faq-container">
        <img
          src={questionsLogo}
          alt="Questions Logo"
          className="questions-logo"
        />
        <h2 className="faq-title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-list-container-parent">
        <div className="faq-list-container">
          {faqData.map((faq, index) => (
            <FaqTile
              key={index}
              logo={faq.logo}
              question={faq.question}
              description={faq.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
