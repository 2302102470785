import React, { useEffect } from "react";
import Header from "../components/Header";
import profileImage from "../assets/profile2.png";
import GpnImage from "../assets/GPM.png";
import PremiumImage from "../assets/premium.png";
import WalletImage from "../assets/wallet.png";
import rupeeImage from "../assets/rupee.png";
import callImage from "../assets/green_call.png";
import heartImage from "../assets/heart.png";
import logoutImage from "../assets/logout.png";
import { useNavigate, useLocation } from "react-router-dom";

const ProfilePage = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  let name, credit, talkHours, helped;
  const navigate = useNavigate();
  if (userData) {
    name = userData.name;
    credit = userData.credit;
    talkHours = userData.talkHours;
    helped = userData.helped;
  }

  const checkLogin = () => {
    if (userData === null) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const handleLogout = () => {
    const confirmed = window.confirm("Are you sure you want to logout?");

    if (confirmed) {
      localStorage.removeItem("userData");
      navigate("/login");
    }
  };

  return (
    <div className="profile-main-container">
      <Header />
      <div className="profile-container">
        <div className="profile-page-image-container">
          <img
            src={profileImage}
            className="profile-page-image"
            alt="Good-People-ProfileImage"
          />
          <img
            src={PremiumImage}
            className="premium-image"
            alt="Good-People-PremiumImage"
          />
        </div>
        <div className="profile-name-container">
          <img
            src={GpnImage}
            className="profile-gpn-image"
            alt="Good-People-GpnImage"
          />

          <h3 className="profile-name">{name}</h3>
        </div>

        {/* <h4 className="profile-GPN-number" >GP No. 100001</h4> */}
        <button className="profile-log-out" onClick={handleLogout}>
          <img src={logoutImage} className="profile-logout-image" />
        </button>
        <div className="profile-card">
          <button
            className="profile-addCredit-button"
            onClick={() => {
              navigate("/premium");
            }}
          >
            <img
              src={WalletImage}
              className="profile-wallet-image"
              alt="Good-People-walletImage"
            />
            <h3 className="profile-name"> + Add Credit</h3>
          </button>
          <div className="profile-card-elements">
            <div className="profile-card-elements-container">
              <div className="profile-card-element-box">
                <img  
                  src={rupeeImage}
                  className="profile-card-image"
                  alt="Good-People"
                />
                <h5 className="profile-card-element-text">Credit</h5>
              </div>
              <div className="profile-card-element-box">
                <img
                  src={callImage}
                  className="profile-card-image"
                  alt="Good-People"
                />
                <h5 className="profile-card-element-text">Talk Hours</h5>
              </div>
              <div className="profile-card-element-box">
                <img
                  src={heartImage}
                  className="profile-card-image"
                  alt="Good-People"
                />
                <h5 className="profile-card-element-text">Helped</h5>
              </div>
            </div>
            <div className="profile-card-elements-container">
              <h5 className="profile-card-element-text">{credit}</h5>
              <h5 className="profile-card-element-text">{talkHours}</h5>
              <h5 className="profile-card-element-text">{helped}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
