import React from 'react'
import { useLocation } from "react-router-dom";

const PodcastDetails = () => {
    const location = useLocation();
  const { path, title } = location.state || {};
  return (
    <div className='podcast-details-container'>
        <div className="premium-page-header">
            <h3 className="premium-page-header-title">Podcast</h3>
            <h3 className="premium-page-header-message">Please don't disclose the contacts, trust the app for secure conversations</h3>
        </div>
        <div className='podcast-detail-card'>
            <div style={{flexDirection:'row'}}>
        <img src={path} alt='Good-People' style={{height:40,width:40}}/>
        <h3 className="podcast-detail-card-title">{title}</h3>
        </div>
        
        </div>
        
    </div>
    
  )
}

export default PodcastDetails