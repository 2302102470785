import React from "react";
import callImage from "../assets/call.png";
import quotesImage from "../assets/quotes-2.png";
import addFriendImage from "../assets/add-friend.png";
import podcastImage from "../assets/podcast-1.png";
import locationImage from "../assets/location.png";
import genderImage from "../assets/gender.png";
import othersImage from "../assets/others.png";

const PremiumCard = () => {
  return (
    <div className="premium-card-container">
      <div className="premium-card-title-text-container">
        <h3 style={{ margin: 0, fontSize: 30, marginBottom: 5 }}>Premium</h3>
        <h3 className="premium-card-text2">₹999/Month</h3>
      </div>
      <div className="premium-card-tile-container">
        <div className="premium-card-tile">
          <img
            src={callImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Call -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Make unlimited calls</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={quotesImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Quotes -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Access 3000+ power quotes</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={addFriendImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Friend's -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Call your friend directly</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={podcastImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Podcast -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Listen to real people's stories</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={locationImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Location -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Talk in your preferred location</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={genderImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Gender -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Speak with specific gender</h3>
          </div>
        </div>
        <div className="premium-card-tile">
          <img
            src={othersImage}
            alt="Good-People"
            className="premium-card-tile-image"
          />
          <div className="premium-card-text1-wrapper">
          <h3 className="premium-card-text1">Others -</h3>
          </div>
          <div className="premium-card-text2-wrapper">
          <h3 className="premium-card-text2">Free all future upgrades</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumCard;
