import React from "react";
import Q1 from '../assets/01.png';
import Q2 from '../assets/02.png';
import Q3 from '../assets/03.png';
import Q4 from '../assets/04.png';
import Q5 from '../assets/05.png';
import Q6 from '../assets/06.png';
import Q7 from '../assets/07.png';
import Q8 from '../assets/08.png';
import Q9 from '../assets/09.png';
import FavoriteStarImage from '../assets/red_star.png'
import PlayImage from '../assets/play.png'
import { useNavigate } from 'react-router-dom'

const data = [
  { id: 1, logoImage: Q1, title: "Stress & Anxiety", duration: "3 Audios" },
  { id: 2, logoImage: Q2, title: "Jobs & Career", duration: "3 Audios" },
  { id: 3, logoImage: Q3, title: "Relationship & Marriage", duration: "3 Audios" },
  { id: 4, logoImage: Q4, title: "Dream & Goals", duration: "3 Audios" },
  { id: 5, logoImage: Q5, title: "Peace & Happiness", duration: "3 Audios" },
  { id: 6, logoImage: Q6, title: "Education & Competition", duration: "3 Audios" },
  { id: 7, logoImage: Q7, title: "Risk & Failure", duration: "3 Audios" },
  { id: 8, logoImage: Q8, title: "Work-life Integration", duration: "3 Audios" },
  { id: 9, logoImage: Q9, title: "Loss & Grief", duration: "3 Audios" },
];

function PodcastTile() {
  const navigate = useNavigate();

  const handlePlayClick =(path,title)=>{
    console.log("handlePlayclick is working");
    navigate('/podcastDetails',{
      state: {
        path: path,
        title: title,
      },
    });
}
  return (
    <div className="podcast-tile-container">
      {data.map((item, index) => (
        <div key={item.id} className={`podcast-tile-item ${index % 2 === 0 ? "row-start" : ""}`}>
          <img src={item.logoImage} alt={item.title} className="podcast-tile-logo" />
          <div className="podcast-tile-content">
            <h3 className="podcast-tile-title">{item.title}</h3>
            <h3 className="podcast-tile-duration">{item.duration}</h3>
          </div>
          <div className="podcast-tile-buttons" >
          <img src={PlayImage} alt='Good-People' className='podcast-tile-playButton' onClick={()=>{handlePlayClick(item.logoImage,item.title)}}/>
          <img src={FavoriteStarImage} alt='Good-People' className='podcast-tile-starButton'/>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PodcastTile;
