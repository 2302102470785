import React from 'react';

const QuoteCard = ({ imagePath, title, onClick }) => {
  return (
    <div className="QuoteCard-cardContainer" onClick={onClick}>
      <img src={imagePath} alt={title} className="QuoteCard-cardImage" />
      <p className="QuoteCard-cardTitle">{title}</p>
    </div>
  );
};

export default QuoteCard;
