import React from "react";
import Header from "../components/Header";
import QuoteCard from "../components/QuoteCard";
import Q1 from '../assets/01.png';
import Q2 from '../assets/02.png';
import Q3 from '../assets/03.png';
import Q4 from '../assets/04.png';
import Q5 from '../assets/05.png';
import Q6 from '../assets/06.png';
import Q7 from '../assets/07.png';
import Q8 from '../assets/08.png';
import Q9 from '../assets/09.png';
import { useNavigate } from "react-router-dom";

const QuotesPage = () => {
  const navigate = useNavigate();

  const quotesData = [
    {
      id: 1,
      imagePath: Q1,
      title: 'Stress & Anxiety',
      quotes: [
        "Take a deep breath, you've got this.",
        "This too shall pass.",
        "Focus on what you can control, let go of what you can't."
      ]
    },
    {
      id: 2,
      imagePath: Q2,
      title: 'Jobs & Career',
      quotes: [
        "Success is not final, failure is not fatal.",
        "Your dream job is out there, keep striving.",
        "Opportunities don’t happen, you create them."
      ]
    },
    {
      id: 3,
      imagePath: Q3,
      title: 'Relationship & Marriage',
      quotes: [
        "Communication is key.",
        "Love grows where trust is planted.",
        "Healthy relationships require effort and compromise."
      ]
    },
    {
      id: 4,
      imagePath: Q4,
      title: 'Dream & Goals',
      quotes: [
        "Dream big, act bigger.",
        "Set goals, crush them, repeat.",
        "Don't just wish for it, work for it."
      ]
    },
    {
      id: 5,
      imagePath: Q5,
      title: 'Peace & Happiness',
      quotes: [
        "Happiness is a choice, choose it daily.",
        "Peace begins with a smile.",
        "Find joy in the little things."
      ]
    },
    {
      id: 6,
      imagePath: Q6,
      title: 'Education & Competition',
      quotes: [
        "Learning is a lifelong journey.",
        "Compete with yourself, not others.",
        "Education is the passport to the future."
      ]
    },
    {
      id: 7,
      imagePath: Q7,
      title: 'Risk & Failure',
      quotes: [
        "Every failure is a step closer to success.",
        "Take risks; the reward is worth it.",
        "Fail forward, not backward."
      ]
    },
    {
      id: 8,
      imagePath: Q8,
      title: 'Work-life Integration',
      quotes: [
        "Balance is not something you find, it's something you create.",
        "Work to live, don't live to work.",
        "Time for work, time for play, balance is the key."
      ]
    },
    {
      id: 9,
      imagePath: Q9,
      title: 'Loss & Grief',
      quotes: [
        "Grief is love persevering.",
        "Take your time to heal.",
        "Remember the happy moments, cherish them."
      ]
    }
  ];

  const handleCardClick = (quoteData) => {
    navigate('/quoteDetails', { state: quoteData });
  };

  return (
    <div>
      <Header />
      <div className="QuotesPage-pageContainer">
        <div className="QuotesPage-grid">
          {quotesData.map((quote) => (
            <QuoteCard
              key={quote.id}
              imagePath={quote.imagePath}
              title={quote.title}
              quotes={quote.quotes}
              onClick={() => handleCardClick(quote)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuotesPage;
