import React from 'react'
import PodcastGroupImage from '../assets/podcast_group.png'
import PodcastGroupPlayPauseImage from '../assets/podcast_grp_PlayPause.png'
import FavoriteStarImage from '../assets/red_star.png'




const PodcastCard = () => {
    

    
  return (
    <div className='podcast-card-container' >
        <div className='podcast-card-image-container'>
            <img src={PodcastGroupImage} alt='Good-People' className='podcast-card-grpImage'/>
            <img src={PodcastGroupPlayPauseImage} alt='Good-People' className='podcast-card-grpPlayPauseImage'/>
        </div>
        <div className='podcast-card-footer'>
            <div className='podcast-card-footer-leftContainer'>
            <h4 className='podcast-card-footer-leftContainer-upperText'>Relationship & Marriage</h4>
            <h4 className='podcast-card-footer-leftContainer-lowerText' >3 Audios</h4>
            </div>
            <img src={FavoriteStarImage} alt='Good-People' className='podcast-card-starImage'/>
        </div>
    </div>
  )
}

export default PodcastCard