import React from "react";
import profileImage from "../assets/wave.png";

const CircularWaveAnimation = () => {
  return (
    <div className="circular-wave-animation">
      <div className="wave-container">
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
        <img src={profileImage} alt="Profile" className="profile-image" />
      </div>
    </div>
  );
};

export default CircularWaveAnimation;
