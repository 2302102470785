import React, { useEffect } from "react";
import AuthPage from "./pages/AuthPage";

import HomePage from "./pages/HomePage";
import PadcastPage from "./pages/PodcastPage";
import FriendsPage from "./pages/FriendsPage";
import QuotesPage from "./pages/QuotesPage";
import ProfilePage from "./pages/ProfilePage";
import RatingPage from "./pages/RatingPage";
import PremiumPage from "./pages/PremiumPage";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import { Route, Routes, useNavigate } from "react-router-dom";
import TalkingPage from "./pages/TalkingPage";
import QuoteDetailsPage from "./pages/QuoteDetailsPage";
import PodcastDetails from "./pages/PodcastDetails";
import FriendRequestPage from "./pages/FriendRequestPage";
import { getMessaging, onMessage } from "firebase/messaging";


function App() {


  useEffect(() => {
    const messaging = getMessaging();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Service Worker registered successfully:", registration);
        })
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
        });
    }    
    // Listen for notification clicks when the app is in the background
    navigator.serviceWorker.ready.then((registration) => {
      registration.addEventListener("notificationclick", (event) => {
        event.notification.close(); // Close the notification
      });
    });

    // (Optional) Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log("Foreground notification received: ", payload);
    });
  }, []);

  

  return (
    // <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/podcast" element={<PadcastPage />} />
        <Route path="/friends" element={<FriendsPage />} />
        <Route path="/qoutes" element={<QuotesPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="/talking" element={<TalkingPage />} />
        <Route path="/quoteDetails" element={<QuoteDetailsPage />} />
        <Route path="/rating" element={<RatingPage />} />
        <Route path="/premium" element={<PremiumPage />} />
        <Route path="/podcastDetails" element={<PodcastDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/notFound" element={<NotFound />} />
        <Route path="/friendRequest" element={<FriendRequestPage />} />
      </Routes>
    // </BrowserRouter>
  );
}

export default App;
