import React from "react";
import { Link, replace, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import HomeWhite from "../assets/home.png";
import HomeBlack from "../assets/home-1.png";
import PodcastWhite from "../assets/podcast.png";
import PodcastBlack from "../assets/podcast-1.png";
import FriendsWhite from "../assets/friends.png";
import FriendsBlack from "../assets/friends-1.png";
import QuotesWhite from "../assets/quotes.png";
import QuotesBlack from "../assets/quotes-1.png";
import ProfileWhite from "../assets/profile.png";
import ProfileBlack from "../assets/profile-1.png";
import NotificationImage from '../assets/notification.png';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the user is logged in
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isLoggedIn = !!userData; // True if userData exists

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={logo} alt="logo" className="image-logo" />
      </div>

      <div className="all-tabs">
        <Link to="/" className="link">
          <div className="tab-container">
            <img src={currentPath === "/" ? HomeBlack : HomeWhite} alt="Home" className="tab-image" />
            <h4 className="header-tab-name">Home</h4>
          </div>
        </Link>
        <Link to="/podcast" className="link">
          <div className="tab-container">
            <img src={currentPath === "/podcast" ? PodcastBlack : PodcastWhite} alt="Podcast" className="tab-image-podcast" />
            <h4 className="header-tab-name">Podcast</h4>
          </div>
        </Link>
        <Link to="/friends" className="link">
          <div className="tab-container">
            <img src={currentPath === "/friends" ? FriendsBlack : FriendsWhite} alt="Friends" className="tab-image" />
            <h4 className="header-tab-name">Friends</h4>
          </div>
        </Link>
        <Link to="/qoutes" className="link">
          <div className="tab-container">
            <img src={currentPath === "/qoutes" ? QuotesBlack : QuotesWhite} alt="Quotes" className="tab-image" />
            <h4 className="header-tab-name">Quotes</h4>
          </div>
        </Link>
        <Link to="/profile" className="link">
          <div className="tab-container">
            <img src={currentPath === "/profile" ? ProfileBlack : ProfileWhite} alt="Profile" className="tab-image" />
            <h4 className="header-tab-name">Profile</h4>
          </div>
        </Link>
      <div className="logo-container" onClick={()=>{navigate('/friendRequest')}}>
        <img src={NotificationImage} alt="notification" className="header-notification-logo" />
      </div>
        
      </div>

      {!isLoggedIn && ( // Show the button only if the user is not logged in
        <button className="header-button" onClick={handleLoginClick}>
          <h4 className="header-button-text">SignUp / SignIn</h4>
        </button>
      )}
    </div>
  );
};

export default Header;
