import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";

AgoraRTC.setLogLevel(4);

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AgoraRTCProvider client={client}>
    <BrowserRouter>
      <>
        <App />
        <Footer />
      </>
    </BrowserRouter>
  </AgoraRTCProvider>
);
