import React from "react";

const About = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>About Us</h1>
      <p>Good People is a platform to connect you with others in meaningful ways.</p>
    </div>
  );
};

export default About;
