import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      {/* Left side text */}
      <div className="footer-left-text">
        © All Rights Reserved 2024, Good People
      </div>
      {/* Right side buttons */}
      <div className="footer-right-buttons">
        <button className="footer-button" onClick={null}>
          Terms & Conditions
        </button>
        <span className="footer-separator">|</span>
        <button className="footer-button" onClick={null}>
          Privacy Policy
        </button>
      </div>
    </div>
  );
};

export default Footer;
