//LOCALHOST
// const API_BASE_URL = 'http://localhost:5001/api';

//LIVE SERVER
const API_BASE_URL = "https://api.goodpeople.wiki/api";

export const LoginApi = async ({ phoneNumber, password,token }) => {
  // console.log(phoneNumber, password);
  try {
    const response = await fetch(`${API_BASE_URL}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        password,
        token
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to Login:", error);
    throw error;
  }
};

export const RegisterApi = async ({
  name,
  phoneNumber,
  email,
  password,
  dateOfBirth,
  gender,
  token
}) => {
  console.log(
    "SignUp Api.js ",
    name,
    phoneNumber,
    email,
    password,
    dateOfBirth,
    gender
  );
  const credit = 60;
  const isPremium = false;
  const isActive = true;
  const isGPN = false;
  const helped = 0;
  const talkHours = 0;
  try {
    const response = await fetch(`${API_BASE_URL}/users/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        phoneNumber,
        email,
        password,
        dateOfBirth,
        credit,
        gender,
        isPremium,
        isActive,
        isGPN,
        helped,
        talkHours,
        token
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("Failed to fetch psychologists:", error);
    throw error;
  }
};

export const AgoraStartApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const userId = userData.userId;
  const token = userData.token;
  console.log('userdata',userData)
  
  console.log("AgoraStartApi UserId:", userId, "Token:", token);

  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-call?userId=${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("AgoraStartApi Failed", e);
  }
};

export const AgoraEndApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const userId = userData.userId;
  const token = userData.token;

  console.log("AgoraEndApi UserId:", userId, "Token:", token);
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/end-call?userId=${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("AgoraEndApi Failed", e);
  }
};

// export const UpdateUserData = async data => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('UpdateUserData Failed', e);
//   }
// };

// export const DeleteUser = async () => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('UpdateUserData Failed', e);
//   }
// };

export const GetFriendDetailsApi = async channelId => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const token = userData.token;
  console.log('GetFriendDetailsApi is working ChannelId received:', channelId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-call?channelId=${channelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('GetFriendDetails Failed', e);
  }
};

export const GetFriendRequestListApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData){
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/friendrequest/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('GetFriendRequestListApi Failed', e);
  }
};

export const GetFriendListApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const userId = userData.userId;
  const token = userData.token;

  console.log('GetFriendListApi is working', userId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/friendlist/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('GetFriendListApi Failed', e);
  }
};

export const SendFriendRequestApi = async ({senderId, receiverId}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const token = userData.token;
  console.log('SendFriendRequestApi is working', senderId, receiverId);
  try {
    const response = await fetch(`${API_BASE_URL}/friend-requests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        senderId,
        receiverId,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('SendFriendRequestApi Failed', e);
  }
};

export const AcceptFriendRequestApi = async (tableId) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const token = userData.token;
  console.log('AcceptFriendRequestApi is working with Table Id', tableId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/${tableId}/accept`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('AcceptFriendRequestApi Failed', e);
  }
};

export const DeleteFriendRequestApi = async (tableId) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const token = userData.token;
  console.log('DeleteFriendRequestApi is working', tableId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/${tableId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('DeleteFriendRequestApi Failed', e);
  }
};

export const CallFriendApi = async ({senderId,receiverId}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if(!userData) return null;
  const token = userData.token;
  console.log('CallFriendApi is working', senderId,receiverId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-callWithFriend`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          senderId,
          receiverId
        }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('CallFriendApi Failed', e);
  }
};

// // Implementation not done
// export const AddQuotesApi = async ({senderId,receiverId}) => {
//   console.log('CallFriendApi is working', senderId,receiverId);
//   const token = await GetToken();
//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/agora/start-callWithFriend`,
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           senderId,
//           receiverId
//         }),
//       },
//     );
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('CallFriendApi Failed', e);
//   }
// };

// export const RazorPayOrderIdApi = async (amount) => {
//   const userId = await GetUserId();
//   console.log('RazorPayOrderIdApi is working with amount:', amount,'userId:',userId);
//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/order/create-order`,
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           amount,
//           userId
//         }),
//       },
//     );
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('RazorPayOrderIdApi Failed', e);
//   }
// };

// export const RazorPayPaymentStatusApi = async ({paymentId,signature,orderId}) => {
//   console.log('RazorPayPaymentStatusApi is working with paymentId:', paymentId,'signature',signature,'orderId',orderId);
//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/order/verify`,
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           paymentId,
//           orderId,
//           signature,

//         }),
//       },
//     );
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('RazorPayPaymentStatusApi Failed', e);
//   }
// };
